export const aboutSection = `Located at 30 Los Osos Valley Rd. Los Osos, CA, we are a
family-owned establishment offering a wide array of fresh, authentic
Mexican food - tostada de ceviche, coctel de camaron, tortas, sopes,
taco salad, enchiladas, nopal huarache, and chimichanga. We use the
freshest ingredients in preparing our food to provide the best
quality and taste. Try our delicious food today and experience the
finest Mexican Food!`;

export const shortAbout = `We are a Mexican food truck dedicated to catering to your culinary
desires with authentic flavors and fresh ingredients. We have the
best tacos in Los Osos—whether you dine in with us or opt for
delivery, we promise a quality meal every time.`;

export const welcomeMessage = `¡Bienvenidos a nuestro rincón de sabor mexicano en Los Osos!`;

export const businessName = `Taqueria Cinco De Mayo`;
