// ImageCarousel.js (Updated)
import React, { useState, useEffect } from "react";
import "./ImageCarousel.css";
import PropTypes from "prop-types";
import ArrowButton from "./ArrowButton";

function ImageCarousel({ images, givenWidth, givenHeight }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    setCurrentImageIndex(0); // Reset currentImage when images prop changes
  }, [images]);

  function selectImage(index) {
    setCurrentImageIndex(index);
  }

  function nextImage() {
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    } else {
      setCurrentImageIndex(0);
    }
  }

  function prevImage() {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    } else {
      setCurrentImageIndex(images.length - 1);
    }
  }

  return (
    <div>
      <div
        className="image-carousel"
        style={{ width: `${givenWidth}px`, height: `${givenHeight}px` }}
      >
        {images.length > 1 && (
          <>
            <div className="next-button" onClick={() => nextImage()}>
              <ArrowButton></ArrowButton>
            </div>
            <div className="prev-button" onClick={() => prevImage()}>
              <ArrowButton></ArrowButton>
            </div>
          </>
        )}

        {/* Apply CSS class for image scaling */}
        <img
          src={images[currentImageIndex].imageURL}
          alt={`${currentImageIndex}`}
          className="carousel-image"
        />
      </div>
      <div className="thumbnails">
        {images.map((image, index) => (
          <img
            key={index}
            src={image.imageURL}
            alt={`Thumbnail ${index}`}
            className={currentImageIndex === index ? "" : "inactive"}
            onClick={() => selectImage(index)}
          />
        ))}
      </div>
    </div>
  );
}

ImageCarousel.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      imageURL: PropTypes.string.isRequired,
    })
  ),
  givenWidth: PropTypes.number.isRequired,
  givenHeight: PropTypes.number.isRequired,
};

export default ImageCarousel;
