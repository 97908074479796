import React, { useState, useEffect, useCallback } from "react";
import Notify from "../../components/Notification";
import PropTypes from "prop-types";
import axios from "axios";

function ProfileDetails(props) {
  const [showNotification, setShowNotification] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState("");
  const [bio, setBio] = useState("");
  const timer = 1100;

  // handles changes to bio: prevents exceeding max char limit and excessive newlines
  function handleChange(event) {
    const inputValue = event.target.value;
    const newlineCount = (inputValue.match(/\n/g) || []).length; // Count the number of newlines

    if (
      newlineCount <= 4 &&
      (inputValue.length < 250 ||
        event.nativeEvent.inputType === "deleteContentBackward")
    ) {
      setBio(inputValue);
    } else {
      triggerNotification("Max Newlines Exceeded!", false);
    }

    if (inputValue.length > 250) {
      setBio(inputValue.slice(0, 249));
    }
  }

  // function for triggering visual notification with a custom message & color indicator (success, fail)
  function triggerNotification(textField, successBool) {
    if (!showNotification) {
      setNotificationMsg(textField);
      setIsSuccessful(successBool);
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false); // Hide notification after 3 seconds
      }, 3300);
    }
  }

  // fetches user profile data to set bio (if user has set their bio)
  const fetchUserProfile = useCallback(async (userID) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_LINK +
          `/users/public-profile/${userID}`
      );
      setBio(response.data.bio);
      if (bio && bio.length > 0) {
        setBio(bio);
      }
    } catch (error) {
      console.error("Error encountered: ", error);
    }
  }, [bio]);

  // saves the bio by sending user inputted bio to the backend: automatically truncates bio
  const saveBio = async () => {
    try {
      if (bio.length === 0) {
        triggerNotification("Text field empty", false);
        return;
      }

      if (bio.length > 249) {
        triggerNotification("Bio is too long", false);
        return;
      }

      await axios.post(
        `${process.env.REACT_APP_BACKEND_LINK}/users/set-bio`,
        {
          userID: props.userID,
          bio: bio.slice(0, 199),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              process.env.REACT_APP_JWT_TOKEN_NAME
            )}`,
          },
        }
      );
      triggerNotification("Bio Saved Successfully", true);
    } catch (error) {
      console.error("Error:", error);
      triggerNotification("Save Unsuccessful", false);
    }
  };

  useEffect(() => {
    fetchUserProfile(props.userID);
  }, [fetchUserProfile, props.userID]);

  return (
    <div className="container-box">
      <div>
        <h5>Profile Details</h5>
        <textarea
          className="vertical-form line-length"
          placeholder="Add your bio here.."
          value={bio}
          onChange={handleChange}
        ></textarea>
        <button className="small-button small-width" onClick={saveBio}>
          Save Bio
        </button>
      </div>
      {showNotification && (
        <Notify
          message={notificationMsg}
          isSuccessful={isSuccessful}
          timer={timer}
        />
      )}
    </div>
  );
}

ProfileDetails.propTypes = {
  userID: PropTypes.string.isRequired,
};

export default ProfileDetails;
