import React, { useState, useEffect, useRef } from "react";
import "./Navbar.css";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { TiShoppingCart } from "react-icons/ti";
import { HiMenu } from "react-icons/hi";
import logo from "../assets/taqueria-logo.png";

function Navbar() {
  const [openMenuBar, setOpenMenuBar] = useState(false);
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });

      setOpenMenuBar(false);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Function to handle clicking outside of the navbar
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpenMenuBar(false);
    }
  };

  // Ref for the mobile menu
  const menuRef = useRef();

  useEffect(() => {
    if (openMenuBar) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [openMenuBar]);

  function handleNavigateToHome() {
    navigate("/home");
    window.scrollTo({ top: 0, behavior: "smooth" });
    setOpenMenuBar(false);
  }

  function handleNavigate(event) {
    event.preventDefault();

    if (location.pathname === "/home") {
      scrollToAboutSection();
    } else {
      navigate("/home#about-section");
    }
    setOpenMenuBar(false);
  }

  function scrollToAboutSection() {
    const aboutSection = document.getElementById("about-section");
    if (aboutSection) {
      const navbarHeight = document.querySelector(".navbar").offsetHeight;
      window.scrollTo({
        top: aboutSection.offsetTop - 60,
        behavior: "smooth",
      });
    }
  }

  return (
    <div className={`navbar ${openMenuBar ? "expanded" : ""}`} ref={menuRef}>
      <div className="desktop-links">
        {screenSize.width <= 600 && (
          <HiMenu
            size={35}
            color={"green"}
            style={{}}
            onClick={() => {
              setOpenMenuBar(!openMenuBar);
            }}
          />
        )}

        {screenSize.width > 600 && (
          <img src={logo} alt="nav-logo" onClick={() => navigate("/home")} />
        )}
        {screenSize.width > 600 && (
          <>
            <Link
              to="/home"
              className="nav-link"
              onClick={handleNavigateToHome}
            >
              Home
            </Link>
            <Link to="/about" className="nav-link" onClick={handleNavigate}>
              About
            </Link>
            <Link
              to="/catering-services"
              className="nav-link"
              onClick={() => {
                navigate("/catering-services");
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              Catering Services
            </Link>
            <Link
              to="/build-order"
              className="nav-link"
              onClick={() => {
                navigate("/build-order");
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              Order Now
            </Link>
          </>
        )}

        <TiShoppingCart
          className="shopping-cart"
          onClick={() => {
            setOpenMenuBar(false);
            navigate("/build-order");
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        />
      </div>
      {openMenuBar && (
        <div className={`mobile-links ${openMenuBar ? "expanded" : ""}`}>
          <Link to="/home" className="nav-link" onClick={handleNavigateToHome}>
            Home
          </Link>
          <Link to="/about" className="nav-link" onClick={handleNavigate}>
            About
          </Link>
          <Link
            to="/catering-services"
            className="nav-link"
            onClick={() => {
              setOpenMenuBar(false);
            }}
          >
            Catering Services
          </Link>
          <Link
            to="/build-order"
            className="nav-link"
            onClick={() => {
              setOpenMenuBar(false);
            }}
          >
            Order Now
          </Link>
        </div>
      )}
      {/* Stripes below Navbar */}
      <div
        className="green-stripe"
        style={{ position: "absolute", bottom: "-10px", left: "0" }}
      ></div>
      <div
        className="red-stripe"
        style={{ position: "absolute", bottom: "-5px", left: "0" }}
      ></div>
    </div>
  );
}

export default Navbar;
