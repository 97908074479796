import React from "react";
import "./GoogleReview.css";

const GoogleReview = () => {
  // Calculate time ago function
  const calculateTimeAgo = (date) => {
    const now = new Date();
    const reviewDate = new Date(date);
    const diffTime = Math.abs(now - reviewDate);
    const diffMonths = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 30));

    if (diffMonths < 12) {
      return `${diffMonths} ${diffMonths === 1 ? "month" : "months"} ago`;
    } else {
      const diffYears = Math.floor(diffMonths / 12);
      return `${diffYears} ${diffYears === 1 ? "year" : "years"} ago`;
    }
  };

  // Hardcoded date for demonstration
  const reviewDate = new Date("2024-02-01");

  // Function to render five gold stars
  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(
        <span key={i} style={{ color: "gold" }}>
          ★
        </span>
      );
    }
    return stars;
  };

  return (
    <div className="google-review">
      <div className="review-header">
        <img
          style={{ height: 35 }}
          src="https://lh3.googleusercontent.com/a-/ALV-UjXJOlnsoHjjOeSdTnk5c3iXU8GvzwQUkeLb-gCPgeZZVVBqOcHf=w60-h60-p-rp-mo-br100"
          alt="Reviewer Avatar"
        />
        <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <a
            href="https://www.google.com/maps/contrib/109289351835221596617?hl=en-US&ved=1t:31294&ictx=111"
            className="tsub-db"
            target="_blank"
            rel="noopener noreferrer"
          >
            Amy Languell
          </a>
          <span style={{ fontSize: "12px", color: "gray" }}>
            11 reviews<span className="aohxlc"> · </span>3 photos
          </span>
        </div>
      </div>
      <div className="g-qf-zge">
        <div className="bg-xi-ye">
          <div className="pua-hbe">
            <div style={{ display: "flex", alignItems: "center" }}>
              {renderStars()} {/* Render five gold stars */}
              <span style={{ fontWeight: "bold", marginLeft: "8px" }}>
                {calculateTimeAgo(reviewDate)}
              </span>
            </div>
          </div>
          <div style={{ marginTop: "5px", marginBottom: "5px", color: "gray" }}>
            <span>Take out</span>
          </div>
          <span>
            Santiago and Pedro are super nice and always make sure my order is
            just right. The pastor and asada is so good and not too dry or too
            fatty like I’ve experienced at other places. Prices are so cheap and
            you get a ton of meat. And they put grilled pineapple on the pastor
            tacos which I love!
          </span>
          <div className="review-footer">
            <span>
              <b>Food</b>: 5/5
            </span>
            <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            <span>
              <b>Service</b>: 5/5
            </span>
            <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            <span>
              <b>Atmosphere</b>: 5/5
            </span>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <img
          style={{ marginLeft: 1, height: 20 }}
          src="https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_92x30dp.png"
          alt="Google Logo"
        />
        <a
          href="https://tinyurl.com/taqueria-google-reviews"
          className="see-all-reviews-link"
          target="_blank"
          rel="noopener noreferrer"
          style={{ marginLeft: "auto" }}
        >
          See all reviews
        </a>
      </div>
    </div>
  );
};

export default GoogleReview;
