import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaCarSide } from "react-icons/fa6";
import { IoBagHandle } from "react-icons/io5";
import OrderCart from "../../components/OrderCart";
import Notification from "../../components/Notification";
import { checkOpenStatus } from "../../utils/timeUtils";
import { MdOutlineShoppingCartCheckout } from "react-icons/md";
import "../Pages.css";

function Order() {
  const [showNotification, setShowNotification] = useState(false);
  const [orderType, setOrderType] = useState(null);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(null);
  const [isDelivering, setIsDelivering] = useState(false);
  const [isOperating, setIsOperating] = useState(false);
  const timer = 3300;
  const navigate = useNavigate();
  const [deliveryInfo, setDeliveryInfo] = useState({
    addressType: "",
    address: "",
    aptNumber: "",
    businessName: "",
    zipCode: "",
    deliveryInstructions: "",
  });
  const [googleMapsUrl, setGoogleMapsUrl] = useState(`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=${encodeURIComponent(deliveryInfo.address + " " + deliveryInfo.zipCode)}`);

  const [cart, setCart] = useState([]);

  useEffect(() => {
    // Load cart from localStorage on component mount
    const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
    setCart(storedCart);
  }, []);

  useEffect(() => {
    // Check open status on component mount
    const fetchOpenStatus = async () => {
      const { isDelivering, isOperating } = await checkOpenStatus();
      setIsDelivering(isDelivering);
      setIsOperating(isOperating);
    };

    fetchOpenStatus();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDeliveryInfo((prevDeliveryInfo) => {  // use callback version of setDeliveryInfo
        const updatedDeliveryInfo = { ...prevDeliveryInfo, [name]: value };
        // conditional used to limit API Calls to Google Maps API
        if (updatedDeliveryInfo.addressType && updatedDeliveryInfo.zipCode && updatedDeliveryInfo.zipCode.length === 5) {
            setGoogleMapsUrl(`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=${encodeURIComponent(updatedDeliveryInfo.address + " " + updatedDeliveryInfo.zipCode)}`);
        }
        return updatedDeliveryInfo;
    });
};
  function triggerNotification(textField, successBool) {
    if (!showNotification) {
      setMessage(textField);
      setSuccess(successBool);
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false); // hide notification after 3 seconds
      }, timer);
    }
  }

  const setOrderSelection = (orderType) => {
    setOrderType(orderType);
    localStorage.setItem("deliveryType", orderType);
  };

  const renderDeliveryForm = () => (
    <form className="delivery-form">
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <div style={{ flexGrow: 3 }}>
          <div>
            <label>
              <span style={{ color: "red" }}>*</span>Address Type:
            </label>
            <select
              name="addressType"
              value={deliveryInfo.addressType}
              onChange={handleInputChange}
              required
            >
              <option value="">Select</option>
              <option value="House">House</option>
              <option value="Apt">Apt</option>
              <option value="Business">Business</option>
            </select>
          </div>
          {deliveryInfo.addressType === "Apt" && (
            <div>
              <label>Apt Number: </label>
              <div>
                <input
                  type="text"
                  name="aptNumber"
                  value={deliveryInfo.aptNumber}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          )}
          {deliveryInfo.addressType === "Business" && (
            <div>
              <label>Business Name: </label>
              <div>
                <input
                  type="text"
                  name="businessName"
                  value={deliveryInfo.businessName}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          )}
          <div>
            <label>
              <span style={{ color: "red" }}>*</span>Address:
            </label>
            <div>
              <input
                type="text"
                name="address"
                value={deliveryInfo.address}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div>
            <label>
              <span style={{ color: "red" }}>*</span>Zip Code:
            </label>
            <div>
              <input
                type="text"
                name="zipCode"
                value={deliveryInfo.zipCode}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div>
            <label>Delivery Instructions: </label>
            <div>
              <textarea
                type="text"
                name="deliveryInstructions"
                value={deliveryInfo.deliveryInstructions}
                onChange={handleInputChange}
                style={{
                  resize: 'none',    // Prevent resizing
                  overflow: 'auto',   // Ensures scrollbars appear if needed
                  height: "75px"
                }}
              />
            </div>
          </div>
          <h6 style={{ color: "red" }}>*These fields are required</h6>
        </div>
        <iframe
          title="dynamic-map"
          src={googleMapsUrl}
          width="260px"
          style={{ border: "solid 1px black", marginLeft: "20px", marginTop: "40px", flexGrow: 1 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="updating-map"
          height="380px"
        />
      </div>
    </form>
  );  

  const renderCarryoutForm = () => (
    <div className="delivery-form" style={{ width: "485px" }}>
      <h5>
        Retaurant Location:
        <a
          style={{
            color: "blue",
            textDecoration: "underline",
            marginLeft: "10px",
            marginBottom: "10px"
          }}
          href="https://www.google.com/maps/place/Taqueria+Cinco+de+Mayo/@35.3110179,-120.8305446,17z/data=!4m6!3m5!1s0x80ece369202069ff:0xfcfec608ec1f27af!8m2!3d35.3111493!4d-120.8299116!16s%2Fg%2F11khy2jxq3?entry=ttu"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div>30 Los Osos Valley Rd. Los Osos, CA</div>
        </a>
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3255.792006198612!2d-120.82991159999999!3d35.31114929999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80ece369202069ff%3A0xfcfec608ec1f27af!2sTaqueria%20Cinco%20de%20Mayo!5e0!3m2!1sen!2sus!4v1719436223894!5m2!1sen!2sus"
            style={{ border: 3 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
            title="Google Maps"
            width="100%"
            height="300px"
          />
        Phone Number: (805)-806-5911
      </h5>
    </div>
  );
  
  const handleCheckout = () => {
    const zipCode = deliveryInfo.zipCode;
    let locationAsString = null;
    if (orderType === "Delivery") {
      if (!deliveryInfo.addressType) {
        triggerNotification("Please Provide an Address Type", false);
        return;
      }

      if (!deliveryInfo.address) {
        triggerNotification("Please enter an address", false);
        return;
      }
      
      if (zipCode && !/^\d{5}$/.test(zipCode)) {
        triggerNotification("Invalid Zip Code (five numerical digits)", false);
        return;
      }

      if (!(zipCode === "93412" || zipCode === "93402")) {
        triggerNotification("We do not deliver to this location", false);
        return;
      }

      let location = deliveryInfo.address;
      if (deliveryInfo.suiteAptType) {
        location = location + " " + deliveryInfo.suiteAptType;
      } else if (deliveryInfo.businessName) {
        location = location + " " + deliveryInfo.businessName;
      }
      location =
        location + " [" + zipCode + "] (" + deliveryInfo.addressType + ")";
        locationAsString = location;
    }

    if (cart.length === 0) {
      triggerNotification(
        "Your cart is empty. Please add items to your cart",
        false
      );
    } else if (orderType === "Delivery" && !locationAsString) {
      triggerNotification("Please confirm the delivery address", false);
    } else if (!orderType) {
      triggerNotification("Please select an Order Method", false);
    } else if (
      orderType === "Delivery" &&
      localStorage.menuTotal &&
      localStorage.menuTotal < 12.99
    ) {
      triggerNotification("Delivery Orders must be at least $12.99", false);
    } else {
      triggerNotification("Proceeding to checkout...", true);
      navigate("/checkout", {
        state: {
          cart,
          orderType,
          deliveryInfo: orderType === "Delivery" ? deliveryInfo : null,
        },
      });
    }
  };

  return (
    <div>
    <div className="standard-page center-container">
    {isOperating ? (
      <>
      <h1>Step 2: Choose Order Method</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        {isDelivering && (<><button
          className={`order-button-selection ${
            orderType === "Delivery" ? "selected" : ""
          }`}
          onClick={() => setOrderSelection("Delivery")}
          style={{ fontFamily: "Mukta", display: "flex", alignItems: "center" }}
        >
          Delivery <FaCarSide />
        </button>
        <div style={{ marginLeft: "10px", marginRight: "10px" }}>OR</div></>)}
        

        <button
          className={`order-button-selection ${
            orderType === "Carryout" ? "selected" : ""
          }`}
          onClick={() => setOrderSelection("Carryout")}
          style={{ fontFamily: "Mukta", display: "flex", alignItems: "center" }}
        >
          Carryout <IoBagHandle style={{ marginBottom: "4px" }} />
        </button>
        
      </div>

      <div className="flex-page">
        <div>
          <OrderCart type="order" />
        </div>
        <div>
          {orderType === "Delivery" && (
            <div className="delivery-form-container">
              {renderDeliveryForm()}
            </div>
          )}
          {orderType === "Carryout" && (
            <div className="delivery-form-container">{renderCarryoutForm()}</div>
          )}
        </div>
      </div>
      <button className="checkout-button" onClick={handleCheckout}>
            Continue to Checkout <MdOutlineShoppingCartCheckout />
          </button>
          {showNotification && (
            <Notification message={message} isSuccessful={success} timer={timer} />
          )}
         
     </>) : ( 
      <h2>Online Orders are closed for today, check again tomorrow.</h2>
    )}
    </div>
     </div>
  );
}

export default Order;
