import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingSpinner from "../../components/LoadingSpinner";
import ProfileDetails from "./ProfileDetails";
import Footer from "../../components/Footer";
import "./Profile.css";

function ProfilePage() {
  const [userProfile, setUserProfile] = useState(null);
  const [deleteConfirmationData, setDeleteConfirmationData] = useState({
    username: "",
    password: ""
  });
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteError, setDeleteError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const fetchUserProfile = useCallback(async () => {
    const token = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN_NAME);
    if (!token) {
      navigate("/login");
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_LINK}/users/profile`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setUserProfile(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch profile data:", error);
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);

  const handleChangePassword = () => {
    navigate("/change-password");
  };

  const handleSignOut = () => {
    localStorage.removeItem(process.env.REACT_APP_JWT_TOKEN_NAME);
    navigate("/login");
  };

  const handleDeleteConfirmation = async () => {
    const token = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN_NAME);
    if (!token) {
      navigate("/login");
      return;
    }

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_LINK}/users/delete`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          },
          data: {
            username: deleteConfirmationData.username,
            password: deleteConfirmationData.password
          }
        }
      );

      if (response.status === 200) {
        localStorage.removeItem(process.env.REACT_APP_JWT_TOKEN_NAME);
        navigate("/login");
      }
    } catch (error) {
      console.error("Failed to delete profile:", error.response);
      if (error.response && error.response.data && error.response.data.error) {
        setDeleteError(error.response.data.error);
      } else {
        setDeleteError("An error occurred while deleting the profile.");
      }
    }
  };

  const confirmDelete = () => {
    setShowDeleteConfirmation(true);
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
    setDeleteError("");
  };

  const handleDelete = (e) => {
    e.preventDefault();
    handleDeleteConfirmation();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDeleteConfirmationData({ ...deleteConfirmationData, [name]: value });
  };

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };

  if (isLoading) {
    return (
      <div className="margin">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      <div className="standard-page flex-page">
        <div className="container-box">
          <form>
            {userProfile && Object.entries(userProfile).map(([key, value]) => (
              <div key={key}>
                <label className="compact">
                  {key === "full_name"
                    ? "Full Name"
                    : key === "phoneNumber"
                    ? "Phone Number"
                    : key
                        .replace(/_/g, " ")
                        .split(" ")
                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(" ")}
                  :
                </label>
                <div>{key === "phoneNumber" ? formatPhoneNumber(value) : value}</div>
              </div>
            ))}
          </form>
          <div className="margin">
            <button className="margin small-button" onClick={handleChangePassword}>
              Change Password
            </button>
            <button className="margin small-button" onClick={handleSignOut}>
              Sign Out
            </button>
          </div>
        </div>

        <ProfileDetails userID={userProfile.userID} />
      </div>

      <div className="vertical-center margin">
        <div className="small-container drop-shadow">
          {!showDeleteConfirmation && (
            <button
              className="span-button"
              style={{
                backgroundColor: "red"
              }}
              onClick={confirmDelete}
            >
              Delete Profile
            </button>
          )}
          {showDeleteConfirmation && (
            <form className="" onSubmit={handleDelete}>
              <div className="margin input">
                <input
                  type="text"
                  name="username"
                  placeholder="Username"
                  value={deleteConfirmationData.username}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="margin input">
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={deleteConfirmationData.password}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="vertical-center flex-column">
                <button
                  className={
                    deleteConfirmationData.username.length > 0 &&
                    deleteConfirmationData.password.length > 0
                      ? "button"
                      : "disabled"
                  }
                >
                  Confirm Delete
                </button>
                <button onClick={handleCancelDelete}>Cancel</button>
              </div>
              {deleteError && <div className="error-message">{deleteError}</div>}
            </form>
          )}
        </div>
      </div>
      <div style={{ marginTop: "175px" }} />
      <Footer />
    </div>
  );
}

export default ProfilePage;
