import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./OrderCart.css";
import images from "../utils/importImages";
import Notification from "./Notification";
import { checkOpenStatus } from "../utils/timeUtils";
import { FaShoppingCart } from "react-icons/fa";

function OrderCart({ type }) {
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(null);
  const timer = 1550;
  const [cartItems, setCartItems] = useState([]);
  const [orderType, setOrderType] = useState(null);
  const [tip, setTip] = useState(0);
  const [tax, setTax] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [fontSize, setFontSize] = useState("25px");
  const navigate = useNavigate();
  const location = useLocation();

  // Define tip options based on current subtotal
  const tipOptions = [
    { label: "No Tip/Cash", value: 0 },
    { label: "10%", value: 0.1 },
    { label: "15%", value: 0.15 },
    { label: "20%", value: 0.2 },
  ];

  const handleTipChange = (selectedTip) => {
    setTip(selectedTip);
  };

  useEffect(() => {
    if (type !== "menu") {
      setFontSize("21px"); // default font size
    } else {
      setFontSize("14px"); // smaller font size for customization menu
    }
  }, [type]);

  // Effect to fetch cart items and calculate totals
  useEffect(() => {
    const fetchCartItems = () => {
      const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
      setCartItems(storedCart);

      const newSubtotal = storedCart.reduce(
        (acc, item) => acc + item.price * item.count,
        0
      );
      setSubtotal(Number(newSubtotal.toFixed(2)));

      const currentOrderType = localStorage.getItem("deliveryType") || "";
      setOrderType(currentOrderType);

      let calculatedTotal = newSubtotal + newSubtotal * 0.0875;
      setTax(Number(newSubtotal * 0.0875).toFixed(2));

      if (currentOrderType === "Delivery") {
        calculatedTotal += 4.99;
      } else {
        calculatedTotal += 1.75;
      }

      if (location.pathname === "/checkout" && tip !== 0) {
        const calculatedTip = tip * newSubtotal;
        calculatedTotal += Number(calculatedTip); // Include tip in total calculation if applicable
        localStorage.setItem("tip", calculatedTip.toFixed(2));
      }

      setTotal(Number(calculatedTotal.toFixed(2)));
      localStorage.setItem("menuTotal", calculatedTotal.toFixed(2));
    };

    fetchCartItems();

    const handleClick = () => {
      fetchCartItems();
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [tip, location.pathname]); // Depend on tip and location.pathname to recalculate totals when they change

  function triggerNotification(textField, successBool) {
    if (!showNotification) {
      setMessage(textField);
      setSuccess(successBool);
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false); // hide notification after 3 seconds
      }, timer);
    }
  }

  const removeFromCart = (itemTitle, itemChoice) => {
    triggerNotification("Removed Item", false);
    const updatedCart = cartItems
      .map((item) => {
        if (item.title === itemTitle && item.choice === itemChoice) {
          if (item.count > 1) {
            return { ...item, count: item.count - 1 };
          }
          return null;
        }
        return item;
      })
      .filter((item) => item !== null);

    setCartItems(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  const addFromCart = (itemTitle, itemChoice) => {
    const updatedCart = cartItems.map((item) => {
      triggerNotification("Item Added", true);
      if (item.title === itemTitle && item.choice === itemChoice) {
        return { ...item, count: item.count + 1 };
      }
      return item;
    });

    setCartItems(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  const handleContinueOrder = async () => {
    const { isOpen, isOperating } = await checkOpenStatus();
    if (isOpen && isOperating) {
      navigate("/order");
    } else if (!isOpen) {
      triggerNotification("Retaurant is closed for today. Check again later");
    } else {
      triggerNotification(
        "Online carryout/delivery is closed for today"
      );
    }
  };

  return (
    <div className="order-cart-container">
      {location.pathname === "/checkout" && orderType === "Delivery" && (
        <div className="tip-section">
          <h3>Tip:</h3>
          <div className="tip-options">
            {tipOptions.map((option, index) => (
              <button
                key={index}
                className={`tip-button ${tip === option.value ? "active" : ""}`}
                onClick={() => handleTipChange(option.value)}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
      )}
      <div className="order-cart">
        {type !== "checkout" && (
          <h2 style={{ display: "flex", alignItems: "center" }}>
            Your Cart <FaShoppingCart style={{ marginLeft: "10px" }} />
          </h2>
        )}
        {!cartItems || cartItems.length === 0 ? (
          <p>Your cart is empty.</p>
        ) : (
          <div>
            {cartItems.map((item, index) => (
              <div className="order-row" key={index}>
                <div className="cart-item-details">
                  <div>
                    <p className="cart-item-entry" style={{ fontSize }}>
                      {item.count} x {item.title}
                      {item.choice && <p> ({item.choice})</p>}
                    </p>
                    <span className="price-each">
                      (${item.price.toFixed(2)} each)
                    </span>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {item.styles && (
                    <span> Styles: {item.styles.join(", ")}</span>
                  )}
                  {type === "menu" && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="remove-button"
                        onClick={() => removeFromCart(item.title, item.choice)}
                      >
                        -
                      </div>
                      <div
                        className="add-button"
                        onClick={() => addFromCart(item.title, item.choice)}
                      >
                        +
                      </div>
                    </div>
                  )}

                  {item.photo ? (
                    <img
                      src={images[item.photo]}
                      className="cart-item-photo"
                      alt={item.title}
                    />
                  ) : (
                    <span>No Photo</span>
                  )}
                </div>

                {showNotification && (
                  <Notification
                    message={message}
                    isSuccessful={success}
                    timer={timer}
                  />
                )}
              </div>
            ))}

            {type === "order" && (
              <div
                style={{
                  paddingTop: "10px",
                  color: "blue",
                  textDecoration: "underline",
                }}
              >
                <Link to="/build-order">Change Order</Link>
              </div>
            )}
          </div>
        )}
        {type === "menu" && cartItems.length > 0 && (
          <div
            className="continue-order-button"
            onClick={() => handleContinueOrder()}
          >
            Continue to Checkout
          </div>
        )}
      </div>

      {(type === "order" || type === "checkout") && (
        <div className="fees-section">
          <div className="order-cart">
            {orderType && orderType === "Delivery" && (
              <div style={{ marginTop: 20 }}>
                <div className="fee">Online Order & Delivery Fee: $4.99</div>
              </div>
            )}

            {orderType && orderType === "Carryout" && (
              <div style={{ marginTop: 20 }}>
                <div className="fee">Online Order Fee: $1.75</div>
              </div>
            )}

            <div className="fee">CA Sales Tax (8.75%): ${tax}</div>
            {location.pathname === "/checkout" && tip !== 0 && (
              <div className="fee">Tip: ${(tip * subtotal).toFixed(2)}</div>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                padding: "0px",
              }}
            >
              <h3>Subtotal:</h3>
              <div>${subtotal}</div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                padding: "0px",
                marginTop: "-20px",
              }}
            >
              <h3>Total:</h3>
              <div style={{ fontSize: "16px" }}>${total}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default OrderCart;
