// StatusBox.js

import React from 'react';
import './StatusBox.css';

const StatusBox = ({ header1, header2, icon, status }) => {
  return (
    <div className="status-box">
      <h1 className="status-title">{header1}</h1>
      {icon && <span className="status-icon">{icon}</span>}
      <h1 className="status-time">
        {status ? (
            <div style={{color:"green"}}>{header2}</div>)
            : (
            <div style={{color:"red"}}>{header2}</div>
        )}
      </h1>
    </div>
  );
};

export default StatusBox;
