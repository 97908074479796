import React from "react";
import "../Pages.css";
import { useLocation } from "react-router-dom";
import OrderCart from "../../components/OrderCart";
import PaymentForm from "./PaymentForm";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Checkout = () => {
  const location = useLocation();
  const { cart, orderType, deliveryInfo } = location.state || {};

  if (!cart || !orderType || !(localStorage.getItem("cart")) || !(localStorage.getItem("deliveryType"))) {
    return <div className="standard-page">Error: Missing order data.</div>;
  }

  return (
    <div className="standard-page">
      <h1>Final Step: Checkout</h1>
      <div className="flex-page">
        <div>
          <Elements stripe={stripePromise}>
            <PaymentForm
              cart={cart}
              orderType={orderType}
              deliveryInfo={deliveryInfo}
            />
          </Elements>
        </div>
        <div className="checkout-information">
          <OrderCart type="checkout" />
          {orderType === "Delivery" && (
            <div style={{ paddingRight: "20px", display: "flex" }}>
              <p>
                Delivery to my {deliveryInfo.addressType} at{" "}
                {deliveryInfo.address} ({deliveryInfo.zipCode})
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Checkout;
