import React, { useState } from "react";
import FoodEntry from "./FoodEntry";
import "./Foodstyling.css";

function FoodCategory({ title, subtitle, items, icon: Icon, imgIcon }) {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="food-category">
      <div className="food-header" onClick={toggleExpanded}>
        <h2 style={{ display: "flex", alignItems: "center" }}>
          {title}
          {Icon && <Icon className="category-icon" />}
          {imgIcon && (
            <img
              src={imgIcon}
              alt={`${title} Icon`}
              style={{ width: 24, height: 24, marginLeft: 10 }}
            />
          )}
        </h2>
        <p className={`arrow-selector ${expanded ? "rotate" : ""}`}>{">"}</p>
      </div>
      {subtitle && expanded && <h3>{subtitle}</h3>}
      {expanded && (
        <div className="menu-category">
          {items.map((item, index) => (
            <FoodEntry key={index} item={item} />
          ))}
        </div>
      )}
    </div>
  );
}

export default FoodCategory;
