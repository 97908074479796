import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { FaGoogle, FaFacebook, FaInstagram } from "react-icons/fa";

function Footer() {
  return (
    <div style={{ marginTop: "100px" }}>
      <footer className="footer">
        <div className="social-icons">
          <a className="social-link" href="https://www.facebook.com/profile.php?id=61561582092226" target="_blank" rel="noopener noreferrer">
            <FaFacebook className="media-icon" size="24" />
          </a>
          <a className="social-link" href="https://www.instagram.com/taqueria.cincodemayo" target="_blank" rel="noopener noreferrer">
            <FaInstagram className="media-icon" size="24" />
          </a>
		  <a className="social-link" href="https://www.google.com/search?q=Taqueria+Cinco+de+Mayo+Los+Osos" target="_blank" rel="noopener noreferrer">
            <FaGoogle className="media-icon" size="24" />
          </a>
        </div>
        <div className="powered-by">
          <div className="team-logo" >Powered by <a className="powered-by-text" href="https://jeepne.com/">JeepNe</a></div>
        </div>
        <div className="terms">
          <Link className="footer-link" to="/terms-of-service">
            Terms of Service
          </Link>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
