// Menu.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import FoodCategory from "../../components/FoodCategory.js";
import OrderCart from "../../components/OrderCart.js";
import ImageCarousel from "../../components/ImageCarousel.js";
import ScrollToTopButton from "../../components/ScrollToTopButton.js";
import LoadingSpinner from "../../components/LoadingSpinner.js";
import Footer from "../../components/Footer.js";
import { businessName } from "../../templateInfo.js";
import { GiTacos } from "react-icons/gi";
import { RiDrinks2Fill } from "react-icons/ri";
import img1 from "../../assets/menu-1.jpg";
import img2 from "../../assets/menu-2.jpg";
import img3 from "../../assets/menu-3.jpg";
import burritoIcon from "../../assets/burrito-icon.png";
import combinationIcon from "../../assets/combinations.png";
import individual from "../../assets/individual.png";

/**
 * Menu Page:
 * Provides the general layout for the Menu displaying menu selection options to add items to cart.
 * The cart is displayed visually at the top for the user to view
 *
 * Code Author: Joshua Estrada
 */

function Menu() {
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    let response = "";
    async function fetchMenuItems() {
      try {
        response = await axios.get(
          process.env.REACT_APP_BACKEND_LINK + "/menu/items"
        );
      } catch (error) {
        console.error("Request Failed: " + error);
      } finally {
        setMenuItems(response.data);
      }
    }

    fetchMenuItems();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const menuImages = [
    { imageURL: img1 },
    { imageURL: img2 },
    { imageURL: img3 },
  ];

  return (
    <div>
      {!menuItems ? (
        <div className="standard-page">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="standard-page">
          <h1>Step 1: Build Your Order</h1> {/* menu header */}
          <OrderCart type="menu" />
          {/* displays the current cart in localStorage */}
          <h4>Begin your order by selecting from our delicious menu below</h4>
          <div className="red-stripe mobile-only"></div>
          {/* desc header */}
          {/* Food Category components: Title (required), Subtitle (optional desc), & Item: filter & map based on item category */}
          <FoodCategory
            title="Combinations"
            subtitle="-- With rice & beans --"
            items={menuItems.filter((item) => item.category === "Combinations")}
            imgIcon={combinationIcon}
          />
          <FoodCategory
            title="Tacos/Tortas"
            subtitle="-- Cilantro & onion, salsa on the side. --"
            items={menuItems.filter((item) => item.category === "Tacos/Tortas")}
            icon={GiTacos}
          />
          <FoodCategory
            title="Burritos"
            subtitle="-- Rice, beans, cheese, pico de gallo, and sour cream --"
            items={menuItems.filter((item) => item.category === "Burritos")}
            imgIcon={burritoIcon}
          />
          <FoodCategory
            title="Individual"
            items={menuItems.filter((item) => item.category === "Individual")}
            imgIcon={individual}
          />
          <FoodCategory
            title="Drinks/Bebidas"
            items={menuItems.filter((item) => item.category === "Drinks")}
            icon={RiDrinks2Fill}
          />
          <div className="red-stripe mobile-only"></div>
          <h3>{businessName} Menu</h3>
          <ImageCarousel
            images={menuImages}
            givenWidth={400}
            givenHeight={600}
          />
          <ScrollToTopButton onClick={scrollToTop} />
        </div>
      )}
      <Footer />
    </div>
  );
}

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export default Menu;
