import React from "react";
import arrow from "../assets/arrow.png";
import "./ArrowButton.css";

function ArrowButton() {
  return (
    <div className="arrow-button">
      <img alt="arrow" src={arrow}/>
    </div>
  );
}

export default ArrowButton;
