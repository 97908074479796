import React from "react";
import About from "./pages/About.js";
import LaunchPage from "./pages/Home.js";
import Menu from "./pages/order/Menu.js";
import Services from "./pages/Services.js";
import Navbar from "./components/Navbar.js";
import Order from "./pages/order/Order.js";
import Checkout from "./pages/order/Checkout.js";
import Orders from "./pages/order/Orders.js";
import SignUp from "./pages/authentication/SignUpPage.js";
import Login from "./pages/authentication/LoginPage.js";
import Profile from "./pages/authentication/ProfilePage.js";
import ProtectedRoute from "./utils/ProtectedRoute.js";
import Recovery from "./pages/authentication/ForgotPasswordPage.js";
import NotFound from "./pages/NotFound.js";

import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="container">
      <BrowserRouter basename="/">
        <Navbar />
        <Routes>
          <Route path="/" element={<LaunchPage />} exact />
          <Route path="/home" element={<LaunchPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/build-order" element={<Menu />} />
          <Route path="/catering-services" element={<Services />} />
          <Route path="/order" element={<Order />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/orders/:orderId" element={<Orders />} />

          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route
						path="/profile"
						element={
							<ProtectedRoute>
								<Profile />
							</ProtectedRoute>
						}
					/>
          <Route path="/forgot-password" element={<Recovery />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
