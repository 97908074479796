import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../../components/LoadingSpinner';
import axios from 'axios';

const Orders = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_BACKEND_LINK + `/order/${orderId}`);
        setOrder(response.data);
        setLoading(false);
      } catch (error) {
        setError('Error retrieving order details');
        setLoading(false);
      }
    };

    fetchOrder();
  }, [orderId]);

  if (error) {
    return<div className="standard-page center-container"><h4>{error}</h4></div> ;
  }

  return (
    <div className="standard-page center-container">
      {!loading ? (
        <div className="details-form">
          <h2>Order Details</h2>
          <p>Amount: ${order.amount}</p>
          <p>Payment Method: {order.payment_method}</p>
          <p>Transaction Date: {new Date(order.transaction_date).toLocaleString()}</p>
          <p>Delivery Type: {order.delivery_type}</p>
          {order.delivery_info && (
                <>
                <p>Delivery Info:</p>
                <ul>
                    <li>
                    <strong>Address:</strong> {order.delivery_info.address}
                    </li>
                    <li>
                    <strong>Zip Code:</strong> {order.delivery_info.zipCode}
                    </li>
                    <li>
                    <strong>Address Type:</strong> {order.delivery_info.addressType}
                    </li>
                    <li>
                    <strong>Delivery Instructions:</strong> {order.delivery_info.deliveryInstructions}
                    </li>
                    {/* Add more details as needed */}
                </ul>
            </>
          )}
          <p>Cart:</p>
          <ul>
            {order.cart.map((item, index) => (
              <li key={index}>
                {item.title} - ${item.price} - Quantity: {item.count}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <LoadingSpinner/>
      )}
    </div>
  );
};

export default Orders;
