// timeUtils.js

import axios from "axios";

// Function to convert time string (HH:MM) to total minutes
export const convertToMinutes = (timeStr) => {
  const [hours, minutes] = timeStr.split(":");
  return parseInt(hours) * 60 + parseInt(minutes);
};

// Function to convert total minutes back to time string (HH:MM)
export const convertToHoursAndMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
};

// Function to convert time to 12-hour format
export const convertTo12HourFormat = (timeStr) => {
  const [hours, minutes] = timeStr.split(":");
  let period = "AM";
  let hour = parseInt(hours);

  if (hour === 0) {
    hour = 12;
  } else if (hour === 12) {
    period = "PM";
  } else if (hour > 12) {
    hour -= 12;
    period = "PM";
  }

  return `${hour}:${minutes}${period}`;
};

// Axios call to fetch open status
export const checkOpenStatus = async () => {
  try {
    const [isOpenResponse, isOperatingResponse] = await Promise.all([
      axios.get(`${process.env.REACT_APP_BACKEND_LINK}/config/is-open`),
      axios.get(`${process.env.REACT_APP_BACKEND_LINK}/config/is-operating`),
    ]);

    const isOpen = isOpenResponse.data.isOpen;
    const isOperating = isOperatingResponse.data.isOperating;
    const isDelivering = isOperatingResponse.data.isDelivering;

    return { isOpen, isOperating, isDelivering };
  } catch (error) {
    console.error("Error fetching open status or operating status:", error);
    throw error;
  }
};

// Axios call to fetch delivery hours
export const fetchDeliveryHours = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_LINK}/config/get_delivery_hours`
    );
    const { start, end } = response.data;
    return { start, end };
  } catch (error) {
    console.error("Error fetching delivery hours:", error);
    throw error;
  }
};

// Axios call to fetch ordering hours
export const fetchOrderingHours = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_LINK}/config/get_carryout_hours`
    );
    const { start, end } = response.data;
    return { start, end };
  } catch (error) {
    console.error("Error fetching ordering hours:", error);
    throw error;
  }
};
