import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { FaCheck, FaTimes } from 'react-icons/fa';
import Notification from "../../components/Notification";
import LoadingSpinner from "../../components/LoadingSpinner";
import axios from "axios";
import "./PaymentForm.css";

const PaymentForm = ({ cart, orderType, deliveryInfo }) => {
  const [showNotification, setShowNotification] = useState(false);
  const [success, setSuccess] = useState(null);
  const [msg, setMsg] = useState('');

  const [processing, setProcessing] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [nameValid, setNameValid] = useState(false); // State for name validation
  const [emailValid, setEmailValid] = useState(false); // State for email validation
  const timer = 1100;
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  function triggerNotification(textField, successBool) {
    if (!showNotification) {
      setMsg(textField);
      setSuccess(successBool);
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false); // hide notification after 3 seconds
      }, timer);
    }
  }

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    setNameValid(e.target.value.length > 0);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailValid(validateEmail(e.target.value.trim()));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!nameValid) {
      triggerNotification("Please enter a name", false);
      return;
    } else if (!emailValid) {
      triggerNotification("Please enter a valid email address", false);
      return;
    }
    setProcessing(true);

    const cardElement = elements.getElement(CardElement);

    try {
      const storedTip = localStorage.getItem("tip");
      const tip = parseFloat(storedTip) >= 0 ? parseFloat(storedTip) : 0;

      // Call backend to calculate payment info and get clientSecret
      const response = await axios.post(process.env.REACT_APP_BACKEND_LINK + "/transaction/create-payment-intent", { cart, tip, deliveryType: orderType, deliveryInfo, name });
      const { clientSecret, paymentInfo, orderId } = response.data;
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: name.trim(),
            email: email.trim(),
          },
        },
      });

      if (error) {
        triggerNotification(error.message, false);
        setProcessing(false);
        return;
      }

      if (paymentIntent.status === 'succeeded') {
        setSucceeded(true);
        await confirmOrder(paymentInfo, orderId);
      } else {
        triggerNotification(`Payment status: ${paymentIntent.status}`, false);
      }

      setProcessing(false);
    } catch (error) {
      triggerNotification(`Payment processing error: ${error.message}`, false);
      setProcessing(false);
    }
  };

  const confirmOrder = async (paymentInfo, orderId) => {
    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_LINK + "/transaction/checkout", {
        cart,
        deliveryType: orderType,
        deliveryInfo,
        paymentInfo,
        name,
        orderId
      });

      localStorage.removeItem('cart');
      localStorage.removeItem('deliveryType');
      localStorage.removeItem('menuTotal');
      localStorage.removeItem('tip');
      navigate("/orders/" + response.data);
    } catch (error) {
      console.error("Error processing transaction:", error.response.data.message);
      triggerNotification(error.response.data.message, false);
    }
  };

  return (
    <div className="cardContainer">
      <h2 className="payment-header">Payment Information</h2>
      <form className="cardForm" onSubmit={handleSubmit}>
        {/* Name Input with Floating Label */}
        <div style={{display:"flex", flexDirection:"flex-row", alignItems: "center"}}>
          <input
            value={name}
            onChange={handleNameChange}
            placeholder="Name"
            className="input-field"
          />
          {name.length > 0 && (
            !nameValid ? (
              <FaTimes className="validation-icon" style={{ color: "#F70000", marginLeft: "5px"}} />
            ) : (
              <FaCheck className="validation-icon" style={{ color: "#047E04", marginLeft: "5px"}} />
            )
          )}
        </div>

        {/* Email Input with Floating Label */}
        <div style={{display:"flex", flexDirection:"flex-row", alignItems: "center"}}>
          <input
            value={email}
            onChange={handleEmailChange}
            placeholder="Email"
            className="input-field"
          />
          {email.length > 0 && (
            !emailValid ? (
              <FaTimes className="validation-icon" style={{ color: "#F70000", marginLeft: "5px" }} />
            ) : (
              <FaCheck className="validation-icon" style={{ color: "#047E04", marginLeft: "5px" }} />
            )
          )}
        </div>


        <CardElement className="card-element-container"/>

        {processing ? <LoadingSpinner/> : (
        <button className="submit-payment" disabled={!stripe || processing || succeeded}>
          Pay
        </button> )}
      </form>
      {showNotification && (
        <Notification message={msg} isSuccessful={success} timer={timer} />
      )}
    </div>
  );
};

export default PaymentForm;
