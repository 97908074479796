import React, { useState, useEffect } from "react";
import LoadingSpinner from "../components/LoadingSpinner";
import Notification from "../components/Notification";
import "./Pages.css";

function Services() {
  const initialFormData = {
    name: "",
    companyName: "",
    email: "",
    phone: "",
    eventType: "",
    questionType: "",
    coordinatorInfo: "",
    eventDate: "",
    eventTime: "",
    eventDuration: "",
    eventAddress: "",
    numberOfGuests: "",
    referral: "",
    comments: "",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState(initialFormData);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationSuccess, setNotificationSuccess] = useState(false);
  const timer = 1000;

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Check if all required fields are filled
    if (!isFormValid()) {
      setLoading(false);
      triggerNotification("Please fill in all required fields", false);
      return;
    }

    fetch(
      `https://script.google.com/macros/s/${process.env.REACT_APP_GOOGLE_SCRIPT_ID}/exec`,
      {
        method: "POST",
        body: new URLSearchParams(formData),
      }
    )
      .then((response) => response.text())
      .then((result) => {
        setFormSubmitted(true);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("There was an error submitting the form");
        setLoading(false);
      });
  };

  const handleResetForm = () => {
    setFormData(initialFormData);
    setFormSubmitted(false);
  };

  const isFormValid = () => {
    return (
      formData.name &&
      formData.email &&
      formData.phone &&
      formData.eventTime &&
      formData.eventDuration &&
      formData.eventAddress &&
      formData.numberOfGuests &&
      formData.referral
    );
  };

  function triggerNotification(message, isSuccess) {
    setNotificationMessage(message);
    setNotificationSuccess(isSuccess);
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000); // Hide notification after 3 seconds
  }

  return (
    <div className="standard-page">
      {!formSubmitted && !loading ? (
        <>
          <div style={{textAlign: "center"}}>
            <h1>Event Information</h1>
            <h3 style={{ marginTop: "0px" }}>
              Fill out the form below & we'll contact you ASAP!
            </h3>
          </div>
          <form className="service-form">
            <h5 style={{ margin: "0px 0px 10px" }}>
              *Indicates the field is required
            </h5>
            <label className="service-label" htmlFor="name">*Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              className="service-field"
              value={formData.name}
              onChange={handleChange}
              placeholder="*Name"
              required
            />
            <label className="service-label" htmlFor="companyName">Company Name (if Applicable):</label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              className="service-field"
              value={formData.companyName}
              placeholder="Company Name (if applicable)"
              onChange={handleChange}
            />
            <label className="service-label"htmlFor="email">*Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              className="service-field"
              value={formData.email}
              onChange={handleChange}
              placeholder="*Email"
              required
            />
            <label className="service-label" htmlFor="phone">*Phone Number:</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              className="service-field"
              value={formData.phone}
              onChange={handleChange}
              placeholder="*Phone Number"
              required
            />
            <label className="service-label" htmlFor="eventType">*Event Type:</label>
            <input
              type="text"
              id="eventType"
              name="eventType"
              className="service-field"
              value={formData.eventType}
              placeholder="*Event Type"
              onChange={handleChange}
            />
            <label className="service-label"htmlFor="coordinatorInfo">
              Event/Wedding Coordinator Contact Info (If Applicable):
            </label>
            <input
              type="text"
              id="coordinatorInfo"
              name="coordinatorInfo"
              className="service-field"
              value={formData.coordinatorInfo}
              placeholder="Coordinator Contact Info (If Applicable)"
              onChange={handleChange}
            />
            <label className="service-label" htmlFor="eventDuration">*Expected Event Duration:</label>
            <input
              type="text"
              id="eventDuration"
              name="eventDuration"
              className="service-field"
              value={formData.eventDuration}
              onChange={handleChange}
              placeholder="*Expected Event Duration"
              required
            />
            <label className="service-label" htmlFor="eventAddress">*Address of Event:</label>
            <input
              type="text"
              id="eventAddress"
              name="eventAddress"
              className="service-field"
              value={formData.eventAddress}
              onChange={handleChange}
              placeholder="*Address of Event"
              required
            />
            <label className="service-label" htmlFor="numberOfGuests">*Number of Guests:</label>
            <input
              type="number"
              id="numberOfGuests"
              name="numberOfGuests"
              className="service-field"
              value={formData.numberOfGuests}
              onChange={handleChange}
              placeholder="*Number of Guests:"
              required
            />
            <label htmlFor="eventDate">*Date of Event:</label>
            <input
              type="date"
              id="eventDate"
              name="eventDate"
              className="service-field"
              value={formData.eventDate}
              onChange={handleChange}
            />
            <label htmlFor="eventTime">*Time of Event:</label>
            <input
              type="time"
              id="eventTime"
              name="eventTime"
              className="service-field"
              value={formData.eventTime}
              onChange={handleChange}
              required
            />
            <label htmlFor="referral">*How did you hear about us?</label>
            <select
              id="referral"
              name="referral"
              style={{marginTop: "5px"}}
              value={formData.referral}
              onChange={handleChange}
              required
            >
              <option value="">Select an option</option>
              <option value="website">Website</option>
              <option value="friend">
                A friend, family member, or colleague
              </option>
              <option value="foodTruck">Food Truck</option>
              <option value="other">Other</option>
            </select>
            <label htmlFor="comments">Additional Comments:</label>
            <textarea
              id="comments"
              name="comments"
              className="service-field"
              value={formData.comments}
              onChange={handleChange}
              style={{
                minWidth: "100%",
                maxWidth: "100%",
                minHeight: "100px",
                maxHeight: "150px",
              }}
            ></textarea>
            <button
              style={{ backgroundColor: "green", color: "white" }}
              onClick={handleSubmit}
            >
              Submit
            </button>
          </form>
        </>
      ) : loading ? (
        <LoadingSpinner />
      ) : (
        <div className="standard-page submission-confirmation">
          <h1>Your form has been submitted, see you soon!</h1>
          <button
            style={{ backgroundColor: "green", color: "white", width: "200px" }}
            onClick={handleResetForm}
          >
            Submit Another Form
          </button>
        </div>
      )}
      {/* Notification Component */}
      {showNotification && (
        <Notification
          message={notificationMessage}
          success={notificationSuccess}
          timer={timer}
        />
      )}
    </div>
  );
}

export default Services;
