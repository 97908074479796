import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./Notification.css"; // Import CSS file for styling

function Notification({ message, isSuccessful, timer }) {
  const [isVisible, setIsVisible] = useState(true);

  // Hide the notification after 3 seconds
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(false);
    }, timer - 300);

    // Cleanup function to clear the timeout when the component unmounts or isVisible changes
    return () => {
      clearTimeout(timeout);
    };
  }, [timer]);

  return (
    <div
      className={`notification ${isVisible ? "visible" : "hidden"}
                    ${isSuccessful ? "success" : "error"}`}
    >
      <p className="notif-text">{message}</p>
    </div>
  );
}

Notification.propTypes = {
  message: PropTypes.string.isRequired,
  isSuccessful: PropTypes.bool,
};

export default Notification;
