// Home.js
import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import ImageCarousel from "../components/ImageCarousel";
import GoogleReview from "../components/GoogleReview";
import ScrollToTopButton from "../components/ScrollToTopButton";
import StatusBox from "../components/StatusBox";
import LoadingSpinner from "../components/LoadingSpinner";
import { FaCarSide } from "react-icons/fa6";
import { BiTime } from "react-icons/bi";
import { MdOutlineFoodBank } from "react-icons/md";
import { aboutSection, welcomeMessage, shortAbout } from "../templateInfo";
import foodtruck from "../assets/food-truck-img.png";
import image1 from "../assets/food1.jpg";
import image2 from "../assets/food2.jpg";
import image3 from "../assets/food3.jpg";
import logo from "../assets/taqueria-logo.png";
import * as timeUtils from "../utils/timeUtils"; // Adjust the import path as per your project structure

function Home() {
  const [status, setStatus] = useState("");
  const [deliveryHours, setDeliveryHours] = useState({
    start: null,
    end: null,
  });
  const [orderingHours, setOrderingHours] = useState({
    start: null,
    end: null,
  });
  const [isDelivering, setIsDelivering] = useState(false);
  const [isOperating, setIsOperating] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const carouselImages = [
    { imageURL: image1 },
    { imageURL: image2 },
    { imageURL: image3 },
  ];

  // Check for open hours using Axios GET call
  const checkOpenStatusHandler = useCallback(async () => {
    try {
      const { isOpen, isOperating, isDelivering } =
        await timeUtils.checkOpenStatus();
      setStatus(isOpen ? "We are OPEN" : "We are CLOSED");
      setIsOpen(isOpen);
      setIsOperating(isOperating);
      setIsDelivering(isDelivering);
    } catch (error) {
      console.error("Error fetching open status or operating status:", error);
    }
  }, []);

  // Fetch delivery hours
  const fetchDeliveryHoursHandler = useCallback(async () => {
    try {
      const { start, end } = await timeUtils.fetchDeliveryHours();
      const startStr = start
        ? timeUtils.convertTo12HourFormat(start) + " -"
        : null;
      const endStr = end ? timeUtils.convertTo12HourFormat(end) : null;
      setDeliveryHours({ start: startStr, end: endStr });
    } catch (error) {
      console.error("Error fetching delivery hours:", error);
      setDeliveryHours({ start: null, end: null });
    }
  }, []);

  // Fetch ordering hours
  const fetchOrderingHoursHandler = useCallback(async () => {
    try {
      const { start, end } = await timeUtils.fetchOrderingHours();
      let startStr = start
        ? timeUtils.convertTo12HourFormat(start) + " -"
        : null;
      let endStr = end ? timeUtils.convertTo12HourFormat(end) : null;

      // Subtract 30 minutes from end time if it exists
      if (end) {
        const endTime = timeUtils.convertToMinutes(end);
        const adjustedEndTime = endTime;
        endStr = timeUtils.convertTo12HourFormat(
          timeUtils.convertToHoursAndMinutes(adjustedEndTime)
        );
      }

      setOrderingHours({ start: startStr, end: endStr });
    } catch (error) {
      console.error("Error fetching ordering hours:", error);
      setOrderingHours({ start: null, end: null });
    }
  }, []);

  useEffect(() => {
    checkOpenStatusHandler();
    fetchDeliveryHoursHandler();
    fetchOrderingHoursHandler();
  }, [
    checkOpenStatusHandler,
    fetchDeliveryHoursHandler,
    fetchOrderingHoursHandler,
  ]);

  useEffect(() => {
    const hash = window.location.hash.substr(1);
    if (hash === "about-section") {
      const aboutSection = document.getElementById("about-section");
      if (aboutSection) {
        aboutSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <div className="standard-page">
        <img
          className="center-logo"
          alt="mobile-logo"
          src={logo}
          style={{ width: "200px" }}
        />
        <div className="wide-container">
          <div className="left-container padding-margin">
            <h1>Order Online for Delivery or Pickup</h1>
            <h5 className="no-margin">{welcomeMessage}</h5>
            <div className="button-container">
              <Link to="/build-order">
                <button className="devpik-button">Build Your Order</button>
              </Link>
            </div>
            {status === "" ? (
              <div style={{ marginTop: "50px" }}>
                <LoadingSpinner />
              </div>
            ) : (
              <div className="flex-row no-margin">
                <StatusBox
                  header1="Operating hours"
                  header2={status}
                  icon={<BiTime />}
                  status={isOpen}
                />
                <StatusBox
                  header1="Delivery Hours Today"
                  header2={`${deliveryHours.start || "CLOSED"} ${
                    deliveryHours.end || ""
                  }`}
                  icon={<FaCarSide />}
                  status={isDelivering}
                />
                <StatusBox
                  header1="Ordering Hours Today"
                  header2={`${orderingHours.start || "CLOSED"} ${
                    orderingHours.end || ""
                  }`}
                  icon={<MdOutlineFoodBank />}
                  status={isOperating}
                />
              </div>
            )}
            <p style={{ marginTop: "10px" }}>{aboutSection}</p>
          </div>
          <div className="right-container padding-margin">
            <iframe
              className="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3255.792006198612!2d-120.82991159999999!3d35.31114929999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80ece369202069ff%3A0xfcfec608ec1f27af!2sTaqueria%20Cinco%20de%20Mayo!5e0!3m2!1sen!2sus!4v1719436223894!5m2!1sen!2sus"
              style={{ border: 3 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
              title="Google Maps"
            />
          </div>
        </div>

        <div className="wide-container" style={{ marginTop: "40px" }}>
          <div className="left-container">
            <iframe style={{marginLeft: "-75px"}} src="https://www.youtube.com/embed/Afi9g5e4z7s?si=MIgq-jH1dlxtU0yi" title="YouTube video player" className="youtube-player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
          </div>
          <div className="right-container">
            <h1>We offer Catering Services!</h1>
            <div className="flex-row">
              <div className="left-container mini-slide" style={{marginLeft:"-45px", marginTop: "-10px"}}>
                <ImageCarousel
                  images={carouselImages}
                  givenHeight={175}
                  givenWidth={300}
                  />    
              </div>
              <div className="right-container">    
                <ul style={{marginLeft:"-20px", marginTop: "10px"}}>
                  <li>Party Platter (Pickup or Delivery)</li>
                  <li>Party Service (Staff on Site)</li>
                  <li>Food Truck Full Service</li>
                  <p style ={{marginTop: "10px", marginLeft: "-25px"}}>Our events are customized for your needs! Call our number or book now on our online form to make a RESERVATION</p>
                </ul>
              </div>
            </div>
            
            <h3 className="mini-headers" style={{ marginTop: "10px"}}>CALL AND MAKE A RESERVATION at (805) 806-5911</h3>
            <Link to="/catering-services">
              <h4 className="mini-headers" style={{ color: "darkblue", marginTop: "-20px"}}>BOOK NOW ONLINE</h4>
            </Link>
          </div>
        </div>

        <div
          id="about-section"
          className="wide-container"
          style={{ marginTop: "40px" }}
        >
          <div className="left-container padding-margin">
            <h1>About Us</h1>
            <img
              className="food-truck-img"
              alt="restaurant-img"
              src={foodtruck}
            />
            <p style={{ marginTop: "12px" }}>{shortAbout}</p>
          </div>
          <div className="right-container padding-margin">
            <h2>Reviews</h2>
            <GoogleReview />
          </div>
        </div>
        <ScrollToTopButton onClick={scrollToTop} />
      </div>
      <Footer />
    </div>
  );
}

export default Home;
