const importAll = (requireContext) => {
    const images = {};
    requireContext.keys().forEach((item, index) => {
        images[item.replace('./', '')] = requireContext(item);
    });
    return images;
};

const images = importAll(require.context('../assets/images', false, /\.(png|jpe?g|svg)$/));

export default images;