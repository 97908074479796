import React, { useState } from "react";
import images from "../utils/importImages";
import Notification from "./Notification";
import "./Foodstyling.css";

function FoodEntry({ item }) {
  const { id, title, price, choices, style, photo, desc } = item;
  const [showNotification, setShowNotification] = useState(false);
  const timer = 950;
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const renderTitle = () => {
    const words = title.split(" ");

    if (words.length > 3) {
      const firstLine = words.slice(0, 3).join(" ");
      const secondLine = words.slice(3).join(" ");
      return (
        <>
          <span>{firstLine}</span>
          <br />
          <span>{secondLine}</span>
        </>
      );
    } else {
      return <span>{title}</span>;
    }
  };

  const addToCart = () => {
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    const selectElement = document.getElementById(`${title}-choices`);
    const selectedChoice = selectElement ? selectElement.value : null;

    const existingItemIndex = cart.findIndex(
      (cartItem) =>
        cartItem.title === title && cartItem.choice === selectedChoice
    );

    if (existingItemIndex >= 0) {
      // item already in cart, increase count
      cart[existingItemIndex].count += 1;
    } else {
      // new item, add to cart with count 1
      const newItem = {
        id,
        title,
        price,
        count: 1,
        choice: selectedChoice, // include selected choice
        styles: style, // include custom styles if applicable
        photo,
      };
      cart.push(newItem);
    }

    localStorage.setItem("cart", JSON.stringify(cart));
    triggerNotification("Added to cart", true);
  };

  function triggerNotification(textField, successBool) {
    if (!showNotification) {
      setMessage(textField);
      setSuccess(successBool);
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false); // hide notification after 3 seconds
      }, timer);
    }
  }

  return (
    <div className="food-entry">
      <div className="food-info">
        <h5 className="food-title">{renderTitle()}</h5>
        <div className="food-photo">
          {photo ? (
            <img src={images[photo]} alt={title} />
          ) : (
            <span>No Photo</span>
          )}
        </div>
        <p>Price: ${price}</p>
        {choices && (
          <div>
            <label htmlFor={`${title}-choices`}>Choices: </label>
            <select style={{ width: "130%" }} id={`${title}-choices`}>
              {choices.map((choice, index) => (
                <option key={index} value={choice}>
                  {choice}
                </option>
              ))}
            </select>
          </div>
        )}
        {style && (
          <div style={{ paddingTop: "10px" }}>
            <div className="styles-checkboxes">
              {style.map((styleChoice, index) => (
                <div key={index} className="style-checkbox">
                  <input
                    type="checkbox"
                    id={`${title}-style-${index}`}
                    value={styleChoice}
                  />
                  <label htmlFor={`${title}-style-${index}`}>
                    {styleChoice}
                  </label>
                </div>
              ))}
            </div>
          </div>
        )}
        {desc && <p style={{ fontSize: "12px" }}>{desc}</p>}
        <button className="cart-add-button" onClick={addToCart}>
          Add to Cart
        </button>
      </div>
      {showNotification && (
        <Notification message={message} isSuccessful={success} timer={timer} />
      )}
    </div>
  );
}

export default FoodEntry;
